import { Redis } from 'ioredis';
import { isProduction } from '@wix/wix-run-mode';

export const withCache = async <T>(
  func: () => Promise<T>,
  redisClient: Redis,
  cacheKey: string,
  ttl: number,
  shouldSkipCache?: (result: T) => boolean
): Promise<T> => {
  const resultFromCache = await redisClient.get(cacheKey);
  if (resultFromCache && resultFromCache?.length > 0) {
    try {
      const parsedResultFromCache = JSON.parse(resultFromCache);
      return parsedResultFromCache;
    } catch {
      // swallow this exception and get data from func
    }
  }
  const result = await func();
  if (shouldSkipCache && shouldSkipCache(result)) {
    return result;
  }
  if (isProduction()) {
    await redisClient.set(cacheKey, JSON.stringify(result), 'EX', ttl);
  }
  return result;
};
